import { Component, HostListener, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router } from "@angular/router";



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent implements OnInit  {

 
  
  @Input() sectionsPositionDataFromFatherComponent:Array<any> = []; //Recibe las posiciones de scroll y la altura de las secciones
  public currentMenuOption:string = 'aboutOption';
  public sticky:boolean = false;
  public ngxScrollToOffsetMap = new Map();
    
                        
  

  @HostListener('window:scroll',['$event']) handleScroll() {
   
    let headerHeight:number = document.getElementById('header').offsetHeight;
    let menuHeight:number = document.getElementById('container-menu').offsetHeight;

  
    //Cuando el usuario mueve el scroll verifica las posiciones y altura de cada seccion 
    //para ver si el scroll esta dentro de ese rango y en consecuencia marca el menu correspondiente

    this.sectionsPositionDataFromFatherComponent.forEach(section => {
      if(window.pageYOffset >= (section.offsetTop - 118 ) && window.pageYOffset <= (section.offsetTop - 118  + section.offsetHeight) ) {
        this.currentMenuOption = section.name;
      }
    });

    //Pone o no la clase sticky al header segun la posición del scroll
    if(window.pageYOffset >= (headerHeight - menuHeight - 12 ) ) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
    
  }

  constructor(public _translateService: TranslateService, private _router:Router) {}

  ngOnInit(){
    this.ngxScrollToOffsetMap
      .set(480, -30)
      .set(768, -103)
      .set(1240, -103)
      .set(1920, -103);
  }
  

}
