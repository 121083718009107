import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomePageComponent} from './components/home-page/home-page.component';


//const routes:Routes = [];
const routes:Routes = [
  {path: '', component:HomePageComponent, pathMatch: 'full', data: { lang:'en' } },
  {path: '**', redirectTo:"/", pathMatch: 'full'}
];

/*
,{
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 100],
    onSameUrlNavigation:'reload'
  }
*/



@NgModule({
 imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
