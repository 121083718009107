import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import { filter } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
   
  public copyrightYear:number;
  public dataRoutePage:any;
  public searchKeywords:string;
  public emailPattern:string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  public isRegisterOnError:boolean;
  
 
  constructor(private _ngxSpinnerService:NgxSpinnerService,  public _translateService: TranslateService, private _router:Router,private _activatedRoute:ActivatedRoute) {
    this._translateService.setDefaultLang('en');
  }

  ngOnInit() {
     this.copyrightYear = new Date().getFullYear();
    // Se saca el data del route para utilizar el parametro page cuando se cambia de idioma 
     this._router.events.pipe(filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        if(this._activatedRoute.root.firstChild.snapshot.data.page){
          this.dataRoutePage = this._activatedRoute.root.firstChild.snapshot.data.page;
          this.searchKeywords = this._activatedRoute.root.firstChild.snapshot.params['keywords']; //Si viene keyword porque se busco la guardo para tenerla en cuanta cuando se cambia el idioma
        }
      });
  
  }




  changeLanguage(lang:string){
    
    //this._translateService.use(lang);
    //this._translateService.currentLang = lang;
      
      this._translateService.get('url.'+this.dataRoutePage).subscribe((res:string) => {
        //console.log(res + (this.searchKeywords?'/'+this.searchKeywords:''));
        this._router.navigate([res + (this.searchKeywords?'/'+this.searchKeywords:'')]);
      });
    
  }


  

  hideSpinner(name:string){
    setTimeout(() => {
      this._ngxSpinnerService.hide(name);
  }, 300);
}


  

}
