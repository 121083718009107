import { Component, OnInit, AfterViewInit, Input, ViewChild, EventEmitter } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import { filter } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { HomePageComponent } from './components/home-page/home-page.component';
import { HeaderComponent } from './components/header/header.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {

  @ViewChild('header') headerComponent:HeaderComponent;
  @ViewChild('homePage') homePageComponent:HomePageComponent;

 public lang:string = 'en';
 

  constructor(private _translateService: TranslateService, private _router:Router, private _activatedRoute:ActivatedRoute) {
   

    this._router.events.pipe(filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        if(this._activatedRoute.root.firstChild.snapshot.data.lang){
          this.lang = this._activatedRoute.root.firstChild.snapshot.data.lang;
        }
        
        this._translateService.setDefaultLang(this.lang);
        this._translateService.use(this.lang);
        this._translateService.currentLang = this.lang;
       
      });
    
  }


  ngOnInit(){
    //Recibo el evento con el array que contiene las posiciones de las secciones, la subscribo y se la envio
    //Al componente header
    this.homePageComponent.sectionPositionEventFromHomePageComponent.subscribe(
      res =>
      {
      this.headerComponent.sectionsPositionDataFromFatherComponent = res;
      }
    );
  }
  

  ngAfterViewInit(){

   

  }

  


}
