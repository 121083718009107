import { Component, OnInit,Output, EventEmitter, HostListener, AfterViewInit  } from '@angular/core';
import { Router} from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})




export class HomePageComponent implements OnInit, AfterViewInit {

  @Output() sectionPositionEventFromHomePageComponent:EventEmitter<any> = new EventEmitter<any>();
 


  public sectionPositionArray:Array<any> = []; //Continene cada seccion con el id / posicion donde comienza / height del contenido
  public stickySection:string = null;

  constructor(private _router:Router,private _ngxSpinnerService:NgxSpinnerService) {}
  
  @HostListener('window:load',['$event']) onload() {
    //Cuando cargan las imagenes e iframes recolecto las section para saber cuanto miden y en que posicion des scroll estan
    let sections:any = document.querySelectorAll('.section');
    sections.forEach(section => {
      this.sectionPositionArray.push({'name':section.id,'offsetTop':section.offsetTop,'offsetHeight':section.offsetHeight});
    });
    this.sectionPositionEventFromHomePageComponent.emit( this.sectionPositionArray ); //Envio al compenente padre el array con las posiciones de las secciones
   
    //console.log(this.sectionPositionArray);
  
  }


  ngOnInit() {
    setTimeout(() => this._ngxSpinnerService.show('sectionSpinner'), 5);
    //this._ngxSpinnerService.show('sectionSpinner');
  
  }


  ngAfterViewInit() {
    this.hideSpinner('sectionSpinner');
   
  }


  hideSpinner(name:string){
      setTimeout(() => {
        this._ngxSpinnerService.hide(name);
    }, 300);
  }



}
